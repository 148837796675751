import { initReactI18next, useTranslation as i18UseTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import durationPlugin from 'dayjs/plugin/duration'
import relativeTimePlugin from 'dayjs/plugin/relativeTime'
import i18n, { TOptionsBase } from 'i18next'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import { z } from 'zod'
import { zodI18nMap } from 'zod-i18n-map'
import csZodTranslation from 'zod-i18n-map/locales/cs/zod.json'
import enZodTranslation from 'zod-i18n-map/locales/en/zod.json'

dayjs.extend(durationPlugin)
dayjs.extend(relativeTimePlugin)

import { Subject } from '@app/data'

import * as CZ_TRANSLATIONS from './cz'
import * as DE_TRANSLATIONS from './de'
import * as EN_TRANSLATIONS from './en'
import * as ES_TRANSLATIONS from './es'
import * as FR_TRANSLATIONS from './fr'

import 'dayjs/locale/cs'
import 'dayjs/locale/en'

export enum SupportedAppLanguage {
  CZECH = 'CZECH',
  ENGLISH = 'ENGLISH',
  GERMAN = 'GERMAN',
  FRENCH = 'FRENCH',
  SPANISH = 'SPANISH'
}

export type ResourceLangType =
  | 'common'
  | 'error'
  | 'classroom'
  | 'settings'
  | 'games'
  | 'performance'
  | 'tutorial'
  | 'dictionary'

export type AppLanguages = 'cs-CZ' | 'en-US' | 'de-DE' | 'fr-FR' | 'es-ES'

export const appLanguageToCode: Record<SupportedAppLanguage, AppLanguages> = {
  [SupportedAppLanguage.CZECH]: 'cs-CZ',
  [SupportedAppLanguage.ENGLISH]: 'en-US',
  [SupportedAppLanguage.GERMAN]: 'de-DE',
  [SupportedAppLanguage.FRENCH]: 'fr-FR',
  [SupportedAppLanguage.SPANISH]: 'es-ES'
}

export const subjectToCode: Record<Subject, AppLanguages> = {
  [Subject.CZECH]: 'cs-CZ',
  [Subject.ENGLISH]: 'en-US',
  [Subject.GERMAN]: 'de-DE',
  [Subject.FRENCH]: 'fr-FR',
  [Subject.SPANISH]: 'es-ES',
  [Subject.ITALIAN]: 'es-ES'
}

export const languageCodeToSupportedLanguage: Record<AppLanguages, SupportedAppLanguage> = {
  'cs-CZ': SupportedAppLanguage.CZECH,
  'en-US': SupportedAppLanguage.ENGLISH,
  'de-DE': SupportedAppLanguage.GERMAN,
  'fr-FR': SupportedAppLanguage.FRENCH,
  'es-ES': SupportedAppLanguage.SPANISH
}

export const languageCodeToShort: Record<string, string> = {
  'cs-CZ': 'cs',
  'en-US': 'en',
  'de-DE': 'de',
  'fr-FR': 'fr',
  'es-ES': 'es'
}

type TOptions = TOptionsBase & { [key: string]: string | number | undefined }

i18n
  .use(initReactI18next)
  .use(intervalPlural)
  .init(
    {
      compatibilityJSON: 'v3',
      lng: 'cs-CZ',
      simplifyPluralSuffix: false,
      pluralSeparator: '_',
      resources: {
        'en-US': { ...EN_TRANSLATIONS, zod: enZodTranslation },
        'cs-CZ': { ...CZ_TRANSLATIONS, zod: csZodTranslation },
        'de-DE': { ...DE_TRANSLATIONS },
        'fr-FR': { ...FR_TRANSLATIONS },
        'es-ES': { ...ES_TRANSLATIONS }
      },
      defaultNS: 'common',
      debug: import.meta.env.DEV, //Just for testing missing key output,
      returnNull: false,
      interpolation: {
        escapeValue: false
      }
    },
    async () => {
      const storedAppLang = localStorage.getItem('lng')
      const appLang = storedAppLang ? appLanguageToCode[storedAppLang as SupportedAppLanguage] : 'cs-CZ'

      await i18n.changeLanguage(appLang).catch((err) => console.info('[i18n] changeLanguage error ', err))

      dayjs.locale(appLang)

      return true
    }
  )
  .catch((error) => console.error('[i18n] init error', error))

z.setErrorMap(zodI18nMap)

export default i18n

export const useTranslation = (ns?: ResourceLangType | ResourceLangType[]) => {
  const { t: i18t } = i18UseTranslation()

  const t = (i18nKey: string, options?: TOptions): string => {
    return i18t<string>(i18nKey, { ...options, ns })
  }

  return { t }
}
