import classNames from 'classnames'

import { authStore } from '@app/app/auth/store/authStore'
import { Avatar, Body } from '@app/components'

type Props = {
  isExpanded: boolean
}

export const UserInfo = ({ isExpanded }: Props) => {
  const { fullName, email } = authStore.useStore((store) => store.userInfo)

  return (
    <div className={classNames('flex flex-row items-center gap-4', !isExpanded && 'mx-auto')}>
      <Avatar fullName={fullName} showTooltip={!isExpanded} size="m" />
      {isExpanded && (
        <div className="flex flex-col items-start">
          <Body size="m" weight="medium" className="text-neutral-300">
            {fullName}
          </Body>
          <Body size="s" weight="medium" className="text-neutral-100">
            {email}
          </Body>
        </div>
      )}
    </div>
  )
}
