import { Link } from 'react-router-dom'
import classNames from 'classnames'
import * as Tooltip from '@radix-ui/react-tooltip'
import { GraduationHat01 } from '@untitled-ui/icons-react'

import { Body } from '@app/components'
import { RouteType } from '@app/config/router/navMenu'

type Props = RouteType & {
  isActive: boolean
  isExpanded: boolean
}

export const ClassroomNavigationItem = ({
  to,
  Icon = GraduationHat01,
  isActive,
  isExpanded,
  label,
  disabled
}: Props) => {
  return (
    <Link
      to={disabled ? '#' : to}
      className={classNames(
        'justify-left relative flex w-full cursor-pointer items-center gap-2 rounded-[10px] border-[1px] px-1 text-sm',
        isActive
          ? 'rounded-[10px] bg-primary-500 text-primary-100 shadow-sm'
          : 'border-primary-600 bg-primary-700 text-primary-300 hover:bg-primary-600',
        isExpanded && 'pl-6'
      )}
    >
      <Tooltip.Provider>
        <Tooltip.Root delayDuration={0}>
          {!isExpanded && (
            <Tooltip.Trigger className="size-11">
              <Body className={classNames('text-primary-200 transition-all')} weight={isActive ? 'bold' : 'regular'}>
                <Icon className="h-full w-full select-none object-cover" />
              </Body>
            </Tooltip.Trigger>
          )}

          <Tooltip.Portal>
            <Tooltip.Content
              sideOffset={20}
              side="right"
              className="z-20 rounded-[10px] bg-white px-[10px] py-2 shadow-2xl"
            >
              <Body size="m" weight="bold">
                {label}
              </Body>
              <Tooltip.Arrow className="fill-white" />
            </Tooltip.Content>
          </Tooltip.Portal>

          {isExpanded && (
            <div className="flex w-full flex-row items-center justify-between gap-2">
              <Body
                className={classNames(
                  'flex-2 truncate text-primary-200 transition-all',
                  isActive && isExpanded && 'py-4 !text-[20px]'
                )}
                weight={isActive ? 'bold' : 'regular'}
              >
                {label}
              </Body>
              <div className={classNames('relative h-12 flex-1')}>
                <div className="float-right size-14">
                  <Icon />
                </div>
              </div>
            </div>
          )}
        </Tooltip.Root>
      </Tooltip.Provider>
    </Link>
  )
}
