import { Suspense, useEffect } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Outlet, useLocation } from 'react-router-dom'

import { Loader } from '@app/components'
import { NewClassDialog } from '@app/modules/classroom/view/NewClassPage/NewClassDialog'
import { LogUtils } from '@app/utils/LogUtils'

import { SideNavigation } from '../SideNavigation'

export const BasicLayout = () => {
  const location = useLocation()

  useEffect(() => {
    LogUtils.logScreenView(location.pathname)
  }, [location])

  useEffect(() => {
    if (isMobileOnly) {
      const viewportMetaTag = document.querySelector('meta[name="viewport"]')
      if (viewportMetaTag) {
        viewportMetaTag.setAttribute('content', 'width=device-width, initial-scale=0.6, maximum-scale=0.8')
      } else {
        const newViewportMetaTag = document.createElement('meta')
        newViewportMetaTag.setAttribute('name', 'viewport')
        newViewportMetaTag.setAttribute('content', 'width=device-width, initial-scale=0.6, maximum-scale=0.8')
        document.head.appendChild(newViewportMetaTag)
      }
    }
  }, [])

  return (
    <Suspense
      fallback={
        <div className="text-pr flex w-screen items-center justify-center">
          <Loader />
        </div>
      }
    >
      <div className="flex w-full bg-primary-1000 text-white">
        <NewClassDialog />

        <main className="flex h-full w-full text-white">
          <div className="w-20">
            <SideNavigation />
          </div>
          <div className="w-[calc(100%-80px)]">
            <Outlet />
          </div>
        </main>
      </div>
    </Suspense>
  )
}
