import * as Sentry from '@sentry/react'

export const initializeSentry = () => {
  Sentry.init({
    dsn: 'https://94237e59c95d4a57af7d64d4d7ab3aab@sentry.dev.foxino.com/2',
    tracePropagationTargets: ['localhost', /^https:\/\/api\.foxino\.com\/graphql/],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.browserProfilingIntegration()
    ],
    // Performance Monitoring
    environment: import.meta.env.VITE_ENVIRONMENT || 'development',
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    enabled: import.meta.env.DEV !== true,
    // ignoreErrors: ['Network Error'],
    // Session Replay
    replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // To set a uniform sample rate
    // Set profiles_sample_rate to 1.0 to profile 100%
    // of sampled transactions.
    // We recommend adjusting this value in production
    profilesSampleRate: 1.0
  })
}
