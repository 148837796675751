import { initApolloClient } from '@app/config/apollo/apolloActions'
import i18n, { languageCodeToShort } from '@app/locales/i18n'

import { authStore } from '../store/authStore'
import { addUnauthorizedHandlerToAxios } from './axiosActions'

export const initAuth = () => {
  addUnauthorizedHandlerToAxios(updateToken, logout)
  initApolloClient()
}

export const login = () => {
  const kc = authStore.useStore.getState().keycloak

  if (!kc) {
    throw new Error('KeyCloak not yet initialized')
  }

  return kc.login()
}

export const logout = () => {
  const kc = authStore.useStore.getState().keycloak

  if (!kc) {
    throw new Error('KeyCloak not yet initialized')
  }

  kc.clearToken()

  const url = kc.createLogoutUrl({ redirectUri: window.location.origin })

  window.location.href = `${url}&kc_locale=${languageCodeToShort[i18n.language]}`

  return Promise.resolve()
}

export const updateToken = async (minValidity = 30): Promise<void> => {
  const kc = authStore.useStore.getState().keycloak

  if (!kc) {
    throw new Error('KeyCloak not yet initialized')
  }

  await kc.updateToken(minValidity)
}

export const redirectToAccountManagement = async () => {
  const kc = authStore.useStore.getState().keycloak

  if (!kc) {
    throw new Error('KeyCloak not yet initialized')
  }

  await kc.accountManagement()
}

export const getKeyCloak = () => {
  const kc = authStore.useStore.getState().keycloak

  return kc
}
